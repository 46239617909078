import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@env';
import { ExternalSignInInput } from '@interfaces/inputs/external-sign-in-input.interface';
import { SignInInput } from '@interfaces/inputs/sign-in-input.interface';
import { JWT } from '@interfaces/jwt.interface';
import { SignInResponse } from '@interfaces/responses/sign-in-response.interface';
import { Observable, map } from 'rxjs';

/**
 * Service responsible for handling authentication-related operations.
 */
@Injectable({ providedIn: 'root' })
export class AuthService {
  /**
   * The HTTP client used for making HTTP requests.
   */
  private readonly http = inject(HttpClient);

  /**
   * Function to authorize user.
   * @param data - The sign-in input data.
   * @returns An observable that emits the sign-in response.
   */
  signIn(data: SignInInput): Observable<SignInResponse> {
    return this.http.post(environment.api + 'auth', data).pipe(map(response => response as SignInResponse));
  }

  /**
   * Function to authorize user via social media.
   * @param data - The external sign-in input data.
   * @returns An observable that emits the sign-in response.
   */
  externalSignIn(data: ExternalSignInInput): Observable<SignInResponse> {
    return this.http.post(environment.api + 'login-external', data).pipe(map(response => response as SignInResponse));
  }

  /**
   * Function to authorize guest.
   * @returns An observable that emits the sign-in response.
   */
  guestSignIn(): Observable<SignInResponse> {
    return this.http.post(environment.api + 'auth_guest', {}).pipe(map(response => response as SignInResponse));
  }

  /**
   * Get new refresh token when JWT token expired.
   * @param refresh_token - The refresh token.
   * @returns An observable that emits the new JWT token.
   */
  getRefreshToken(refresh_token: string): Observable<JWT> {
    return this.http.post(environment.api + 'refresh-token', { refresh_token }).pipe(map(token => token as JWT));
  }
}
